import { useState, useEffect } from "react";
import React from "react";
import "./MediaContainer.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useI18nSection } from "../../utils/languageUtils";

export const MediaContainer = () => {
  const [settings, setSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  });

  const sectionText = useI18nSection("buyGuide");


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSettings({
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        setSettings({
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
        });
      } else {
        setSettings({
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
        });
      }
    };

    handleResize(); // Check initial viewport width
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);

  return (
    <div id="mediaContent"
      style={{
        backgroundImage: "url(./img/sealana/how-to-bg.svg)",
        backgroundRepeat: "none",
        backgroundPosition: "cover",
        width: "100%",
        height: "auto",
      }}
    >
      <div className="howtobuy">
        <div className="howToBuy_Text">
          <h3>{sectionText?.title}</h3>
          <p>
              {sectionText?.description}
          </p>
        </div>
        <div className="howToBuy_Img">
          <img
            src="img/sealana/how-to-img.png"
            alt=""
            srcSet=""
          />
        </div>
        <img
          className="howToBuy_Img_footer"
          src="img/sealana/how-to-bottom.png"
          alt=""
          srcSet=""
        />
      </div>
    </div>
  );
};
