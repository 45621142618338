import React from "react";
import { Navbar, Nav, Container, Row, Col, Card } from "react-bootstrap";
import { useI18nSection } from "../../utils/languageUtils";
import "./outmore.css";
export const OutMore = () => {
  const sectionText = useI18nSection("about");

  return (
    <div id="outMore"
      style={{
        backgroundImage: "url(https://sealana.io/assets/images/about.png)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        width: "100%",
        minHeight: "81.2rem",
        height: "auto",
      }}
    >
      <div  className="">
        <div className="wrapper about">
          <img
            className="wrapper_img "
            src="https://sealana.io/assets/images/about-thread.svg"
            alt=""
            srcSet=""
          />
          <div className="about-wrapper">
            <div className="about-frame ">
              <div className="about-info">
                <div className="title-wrapper">
                  <h2>{sectionText?.title}</h2>
                  <img
                    src="https://sealana.io/assets/images/svg-icons/logo.svg"
                    alt="sealana"
                    srcSet=""
                  />
                </div>
                <p>
                  {sectionText?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
